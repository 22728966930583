.ui.table#add-new-project,
.ui.table#add-new-project tbody,
.ui.table#add-new-project tbody tr,
.ui.table#add-new-project tbody tr td {
  display: flow-root;
  background: "white";
}

.ui.table#add-new-project #site-tabs .ui.menu {
  overflow-x: scroll;
}

#add-new-project .segment.half-width {
  width: 50%;
}

#add-new-project #parcel-search-description {
  color: darkslategray;
  font-size: x-small;
}
