.building-output-table {
  overflow: auto;
  height: 100%;
  flex: 0 1 auto;
}

.ui.sortable.table thead th.sorted {
  white-space: inherit;
  vertical-align: initial;
}

.ui.sortable.table thead th.sorted:not(.column):hover {
  background: #f2f2f2 !important;
}

.ui.sortable.table thead th.sorted.column {
  background: #e0e0e0 !important;
}

.ui.sortable.table thead th.sorted:not(.column) {
  background: #f9fafb !important;
}

.ui.sortable.table thead th.sorted:not(.column)::after {
  opacity: 0;
}

.ui.sortable.table thead .Building.Option {
  min-width: 9rem;
}

.ui.sortable.table thead .Stories {
  min-width: 6rem;
}

.ui.button.building-table-action,
.ui.button.building-table-action:hover,
.ui.active.button.building-table-action,
.ui.active.button.building-table-action:hover {
  background-color: #1cb5ac;
  color: white;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.building-table-action.blinking {
  animation: shadow-pulse 1s infinite;
}

.main-building-icon {
  width: 1px;
}

.ellipsis-button-cell {
  padding: 5px;
  width: 1px;
}

.empty-table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
