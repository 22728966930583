.ui.icon.input.projectSearch > input {
  border: none;
}

.ui.tiny.projectListHeader.menu {
  margin: 0;
  border-radius: 0;
  border-right: none;
}

.ui.tiny.projectListHeader.menu .addProject {
  width: 95px;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.mini.editProjectsControls.menu {
  margin: 0px;
  background-color: white;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.mini.editProjectsControls.menu > .item {
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
}
