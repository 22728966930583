.ui.buttons {
  margin-right: 15px !important;
  margin-bottom: 5px !important;
}

.edit-button.active {
  background-color: white !important;
  box-shadow: 0 0 0 2px #54B2AB inset !important;
}

.edit-button.active i {
  color: #54B2AB;
}