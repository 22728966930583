.ui.bottom.sidebar {
  overflow-y: visible !important;
}

#building-options-panel {
  display: flex;
  flex-flow: column;
  background-color: white;
  overflow: visible;
}

#building-options-panel .menu .item {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.resizer-top {
  position: relative !important;
  background-color: lightgray;
  height: 10px !important;
  flex-shrink: 0;
}

/* 
* Note(gushuro: copied from semantic style, allowing style when there's an intermediate span.
* This prevents project controls line to jump while default zoning is retrieved
*/
.ui.menu:not(.vertical) .item > span > .button {
  position: relative;
  top: 0em;
  margin: -0.5em 0em;
  padding-bottom: 0.78571429em;
  padding-top: 0.78571429em;
  font-size: 1em;
}

.ui.menu .item.hidden {
  visibility: hidden;
}
