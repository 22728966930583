#info-message {
  background-color: white;
  border: 0;
  border-radius: 10px;
  position: absolute;
  bottom: calc(0% + 15px);
  left: 50%;
}

#info-message .header {
  color: #54B2AB
}

#info-message label {
  white-space: pre-wrap;
}