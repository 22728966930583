.ui.modal.informative-modal > .header,
.ui.modal.informative-modal > .description {
  text-align: center;
  background-color: #23b3aa;
  border: 0;
  font-size: x-large;
  font-weight: bold;
  padding-bottom: 17px;
}

.ui.modal.informative-modal > .content {
  display: grid;
  border: 0;
  padding-bottom: 17px;
  padding-left: 70px;
  padding-right: 70px;
}

.ui.modal.informative-modal > .content > label.content {
  text-align: justify;
  font-size: 1.2em;
  font-weight: 500;
}

.ui.modal.informative-modal > .content > button.suggestion {
  margin-top: 20px;
}

.ui.modal.informative-modal > .content > button.suggestion.active {
  background-color: rgb(33, 186, 69);
  color: white;
}

.ui.modal.informative-modal > .actions {
  text-align: center;
  border: 0;
  background-color: white;
}

.ui.modal.informative-modal > .actions > button {
  background-color: #466562;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ui.modal.informative-modal > .actions > .ui.loader {
  color: black;
}

.ui.modal.informative-modal > .actions > .ui.loader::before {
  border-color: rgba(11, 11, 11, 0.15);
}

.ui.modal.informative-modal > .actions > .ui.loader::after {
  border-color: #212121 transparent transparent;
}
