.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.penciler-loader {
  display: inline-block;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #46a563;
  border-right: 10px solid #f3f3f3;
  border-bottom: 10px solid #46a563;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  animation: spin 2s linear infinite;
  font-family: Oswald;
}

.table-loader {
  display: inline-block;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #46a563;
  border-right: 10px solid #f3f3f3;
  border-bottom: 10px solid #46a563;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  font-family: Oswald;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes addBuildingPulse {
  from {
    background-color: #ffffff;
  }
  to {
    background-color: #b5d2fe;
  }
}

.loading.mapboxgl-map .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: wait;
}

.secondary-dropdown {
  background-color: "white" !important;
}

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.print-page {
  margin: 10px;
  padding: 10px;
  width: 670px; /* width: 7in; */
  height: 900px; /* or height: 9.5in; */
  clear: both;
  page-break-after: always;
}

.add-building-button {
  animation: addBuildingPulse 2s linear infinite;
}

.textarea-label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.layer-toggle {
  background-color: #ffffff;
  height: 45px;
  width: 45px;
  border: 2px solid #b8bdbe;
  border-radius: 8px;
}

.layer-toggle:hover {
  cursor: pointer;
}

.layer-controls {
  width: 250px;
  float: right;
}

.ui.button.confirm-polygon-button {
  margin-top: 10px;
  margin-right: 8px;
}

.form-label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}

.flex-box-container {
  display: flex;
  align-items: center;
  align-content: center;
}

#layer-legend {
  background-color: white;
  width: 200px;
}

.color-container {
  display: flex;
  justify-content: center;
  margin-right: 25px;
}
